<template>
  <div class="preview_box">
    <div class="paper_title">
      <h3><span>总分：{{ paperInfo.sunScore }}</span>{{ paperInfo.title }}</h3>
      <el-button type="warning" class="el-icon-d-arrow-left" @click="isShowList(true)">返回试题列表</el-button>
    </div>
    <div class="info_box">
      <div v-for="(item, index) in paperOptions" :key="item.id">
        <h4>{{ index + 1 }}、{{ item.content }}</h4>
        <p v-for="(citem, index) in item.options" :key="index">{{ index + 1 }}、{{ citem.content }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api/index'
export default {
  name: "paper-preview",
  props: {
    paperInfo: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      paperOptions: []
    }
  },
  methods: {
    isShowList(val) {
      this.$emit('showQlist', val)
    },
    //试卷预览
    async previewPaper(ids) {
      try {
        let res = await api.paperPreview(ids)
        if (res.code == 200) {
          this.paperOptions = res.data
        }

      } catch (e) {

      }
    },
  },
  watch: {
    paperInfo: {
      handler(newVal, oldVal) {
        this.previewPaper(newVal.questionIds)
      },
      deep: true
    }
  }
}
</script>

<style scoped lang="less">
.preview_box {
  padding: 12px 12px 0 12px;
  height: 100%;
  text-align: right;

  .paper_title {
    display: flex;
    align-items: center;

    h3 {
      text-align: center;
      line-height: 36px;
      font-size: 18px;
      font-weight: bold;
      flex: 1;
      margin-right: 20px;

      span {
        float: left;
      }

    }
  }

  .info_box {
    margin-top: 10px;
    height: calc(100% - 46px);
    width: 100%;
    overflow: auto;

    div {
      text-align: left;
      margin-top: 10px;
      line-height: 24px;

      h4 {
        font-weight: bold;
      }
    }
  }
}
</style>
