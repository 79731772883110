<template>
  <div class="form_box">
    <div class="box">
      <div class="one">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
          <el-form-item label="试卷名称" prop="title" placeholder="请输入试卷名称">
            <el-input v-model="ruleForm.title" :maxlength="20" />
          </el-form-item>
          <el-form-item label="试卷类型" prop="type">
            <el-select v-model="ruleForm.type" style="width: 100%">
              <el-option v-for="item in paperType" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="基础积分" prop="userScore">
            <el-input v-model="ruleForm.userScore" />
          </el-form-item>
          <el-form-item label="奖励积分" prop="bonusScore" v-if="ruleForm.type == 1">
            <el-input v-model="ruleForm.bonusScore" />
          </el-form-item>
          <el-form-item label="奖励方式" v-if="ruleForm.type == 1" prop="bonusMethod">
            <el-radio v-model="ruleForm.bonusMethod" :label="1">根据正确率</el-radio>
            <el-radio v-model="ruleForm.bonusMethod" :label="2">根据得分</el-radio>
          </el-form-item>
          <el-form-item label="正确率" prop="bonusThreshold" v-if="ruleForm.type == 1 && ruleForm.bonusMethod == 1">
            <el-input v-model="ruleForm.bonusThreshold" placeholder="填写 x ，表示正确率超过 x % 时奖励积分" />
          </el-form-item>
          <el-form-item label="得分" prop="bonusThreshold" v-if="ruleForm.type == 1 && ruleForm.bonusMethod == 2">
            <el-input v-model="ruleForm.bonusThreshold" placeholder="填写得分，表示超过该得分时奖励积分" />
          </el-form-item>
        </el-form>
      </div>
      <div class="two">
        <question-list :isChildren="true" @showQlist="showQlist" v-show="qListShow" @getQuestions="getQuestions"
          :selectRows="selectRows" />
        <paper-preview @showQlist="showQlist" v-show="!qListShow" :paperInfo="paperInfo" />
      </div>
    </div>
    <div class="btn_box">
      <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
      <el-button @click="cancelFrom">取消</el-button>
    </div>
  </div>
</template>

<script>
import questionList from '../../exam-question/index'
import PaperPreview from './paper-preview'
import { paperType } from '@/utils/baseData'
import api from '@/api/index'
import debounce from 'lodash/debounce'
export default {
  name: 'add-test',
  props: {
    operateBox: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      paperType: paperType,
      testTips: [],
      questionTypeValue: '',
      questionType: [],
      radio: '选中且禁用',
      ruleForm: {
        title: '',
        type: 1,
        userScore: 0,
        bonusScore: 0,
        bonusMethod: 1,
        bonusThreshold: '',
        sunScore: null,
        questionIds: null,
        id: null
      },
      rules: {
        title: [
          { required: true, message: '请输入试卷名称', trigger: 'blur' }
        ],
        type: [
          { required: true, message: '不为空', trigger: 'blur' }
        ],
        userScore: [
          { required: true, message: '只能输入数字' },
          {
            type: 'number',
            pattern: '/^[1-9]d{0,5}$/',
            message: '请输入整数，并且注意空格',
            trigger: 'blur',
            transform: (value) => Number(value)
          },
          {
            validator: (rule, value, callback) => {
              if ((value && value.toString().length > 2) || value < 0) {
                callback(new Error('不可超过2位数,不可小于0'))
              } else {
                callback()
              }
            },
            trigger: 'blur'
          }
        ],
        bonusScore: [
          { required: true, message: '只能输入数字' },
          {
            type: 'number',
            pattern: '/^[1-9]d{0,5}$/',
            message: '请输入整数，并且注意空格',
            trigger: 'blur',
            transform: (value) => Number(value)
          },
          {
            validator: (rule, value, callback) => {
              if ((value && value.toString().length > 3) || value < 0) {
                callback(new Error('不可超过3位数,不可小于0'))
              } else {
                callback()
              }
            },
            trigger: 'blur'
          }
        ],
        bonusMethod: [
          { required: true, message: '不为空', trigger: 'blur' }
        ],
        bonusThreshold: [
          { required: true, message: '不为空', trigger: 'blur' },
          { validator: this.bonusThresholdValidator, trigger: 'blur' }
        ]
      },
      qListShow: true,
      paperInfo: {},
      selectRows: []
    }
  },
  components: {
    PaperPreview,
    questionList
  },
  created() {
    if (this.operateBox.id != null) {
      this.getPaperInfo()
    }
  },
  methods: {
    bonusThresholdValidator(rule, value, callback) {
      const bonusMethod = this.ruleForm.bonusMethod;
      const totalScore = this.ruleForm.sunScore;
      const parsedValue = parseFloat(value);

      if (Number.isNaN(parsedValue)) {
        callback(new Error('请输入有效数字'));
      } else if (!Number.isInteger(Number(value))) {
        callback(new Error('请输入整数'));
      } else if (bonusMethod === 1) { // 根据正确率
        if (parsedValue > 0 && parsedValue <= 100) {
          callback();
        } else {
          callback(new Error('取值应在0到100之间的整数'));
        }
      } else {  // 根据得分
        if (!totalScore) { //如果没有总分
          callback(new Error('请先选择试题,确定试卷总分'));
        }
        if (parsedValue > 0 && parsedValue <= totalScore) {
          callback();
        } else {
          callback(new Error(`取值应在0到${totalScore}之间的整数`));
        }
      }
    },

    // 根据试卷id获取详情用户编辑回显
    async getPaperInfo() {
      const res = await api.paperInfoGet(this.operateBox.id)
      if (res.code == 200) {
        this.ruleForm = {
          title: res.data.title,
          type: res.data.type,
          userScore: res.data.userScore,
          bonusScore: res.data.bonusScore,
          bonusMethod: res.data.bonusMethod,
          bonusThreshold: res.data.bonusThreshold,
          sunScore: res.data.sunScore,
          questionIds: res.data.questionIds,
          id: res.data.id
        }
      }
      this.selectRows = res.data.questionIds.split(',')
    },
    // 获取试题列表
    submitForm: debounce(function (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.ruleForm.sunScore == null || this.ruleForm.sunScore == '') {
            this.$message.warning('请选择至少一道试题')
            return false
          }
          if (this.operateBox.operate == 'add') {
            if (this.ruleForm.type == 1) {
              this.addPaper({
                title: this.ruleForm.title,
                type: Number(this.ruleForm.type),
                userScore: Number(this.ruleForm.userScore),
                bonusScore: Number(this.ruleForm.bonusScore),
                bonusMethod: Number(this.ruleForm.bonusMethod),
                bonusThreshold: Number(this.ruleForm.bonusThreshold),
                questionIds: this.ruleForm.questionIds.toString()
              })
            } else {
              this.addPaper({
                title: this.ruleForm.title,
                type: Number(this.ruleForm.type),
                userScore: Number(this.ruleForm.userScore),
                bonusScore: null,
                bonusMethod: null,
                bonusThreshold: null,
                questionIds: this.ruleForm.questionIds.toString()
              })
            }
          } else {
            if (this.ruleForm.type == 1) {
              this.updatePaper({
                id: this.ruleForm.id,
                title: this.ruleForm.title,
                type: Number(this.ruleForm.type),
                userScore: Number(this.ruleForm.userScore),
                bonusScore: Number(this.ruleForm.userScore),
                bonusMethod: Number(this.ruleForm.bonusMethod),
                bonusThreshold: Number(this.ruleForm.bonusThreshold),
                questionIds: this.ruleForm.questionIds.toString()
              })
            } else {
              this.updatePaper({
                id: this.ruleForm.id,
                title: this.ruleForm.title,
                type: Number(this.ruleForm.type),
                userScore: Number(this.ruleForm.userScore),
                bonusScore: null,
                bonusMethod: null,
                bonusThreshold: null,
                questionIds: this.ruleForm.questionIds.toString()
              })
            }
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }, 500),
    async addPaper(data) {
      try {
        const res = await api.paperAdd(data)
        if (res.code == 200) {
          this.$message.success('新增试卷成功')
          this.$emit('closed')
        } else {
          this.$message.success('新增试卷失败')
        }
      } catch (e) {
        this.$message.warning('系统出错')
      }
    },
    async updatePaper(data) {
      try {
        const res = await api.paperUpdate(data)
        if (res.code == 200) {
          this.$message.success('修改试卷成功')
          this.$emit('closed')
        } else {
          this.$message.success('修改试卷失败')
        }
      } catch (e) {
        this.$message.warning('系统出错')
      }
    },

    cancelFrom() {
      this.$emit('closed')
    },
    showQlist(val) {
      if (this.ruleForm.title == '') {
        this.$message.warning('请输入试卷名称')
        return false
      }
      if (this.ruleForm.questionIds == null || this.ruleForm.questionIds == '') {
        this.$message.warning('请选择至少一道试题')
        return false
      }
      this.qListShow = val
      this.paperInfo = {
        title: this.ruleForm.title,
        sunScore: this.ruleForm.sunScore,
        questionIds: this.ruleForm.questionIds
      }
    },
    getQuestions(data) {
      this.ruleForm.sunScore = data.sunScore
      this.ruleForm.questionIds = data.paperQids
    }
  }
}
</script>

<style scoped lang="less">
.form_box {
  height: 500px;

  .box {
    height: calc(100% - 46px);
    display: flex;

    .one {
      padding: 12px 12px 0 0;
      flex: 1;
      border: 1px solid #f8f8f8;
    }

    .two {
      flex: 2;
      margin-left: 12px;
      border: 1px solid #f8f8f8;
    }
  }

  .btn_box {
    text-align: right;
    margin-top: 10px;
  }
}
</style>
