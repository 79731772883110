<template>
  <div class="info_box">
    <div class="three">
      <div class="title">
        <span style="float: left" v-if="paperInfo.type == 1">【考题】</span>
        <span style="float: left" v-else>【问卷】</span>
        {{ paperInfo.title }}
        <span style="float: right">总分：{{ paperInfo
          .questionScoreSum }}分</span>
      </div>
      <div class="crt_bxo">创建人:admin <span style="margin-left: 10px">创建时间:{{ paperInfo.createTime }}</span></div>
      <div class="score_box">
        <span>基础积分:{{ paperInfo.userScore }}</span>
        <span v-if="paperInfo.type == 1">奖励积分:{{ paperInfo.bonusScore }}</span>
      </div>
      <div class="score_box">
        <span v-if="paperInfo.bonusMethod == 1"> 正确率达到{{ paperInfo.bonusThreshold }}%，可获得奖励积分 </span>
        <span v-if="paperInfo.bonusMethod == 2">得分达到{{ paperInfo.bonusThreshold }}分，可获得奖励积分</span>
      </div>
    </div>
    <div class="info_content">
      <div class="info_list" v-for="(item, index) in paperInfo.question" :key="item.id">
        <el-divider></el-divider>
        <h4>{{ index + 1 }}、{{ item.content }}</h4>
        <p v-for="(citem, index) in item.options" :key="citem.optionId">{{ index + 1 }}、{{ citem.content }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api/index'
export default {
  name: 'paper-details',
  props: {
    paperId: {
      type: Number,
      required: true
    },
  },
  data() {
    return {
      paperInfo: {}
    }
  },
  created() {
    this.getPaperInfo()
  },
  methods: {
    async getPaperInfo() {
      try {
        let res = await api.paperInfoGet(this.paperId)
        if (res.code == 200) {
          this.paperInfo = res.data
        }
      } catch (e) {

      }
    }
  }
}
</script>

<style scoped lang="less">
.info_box {
  height: 500px;

  .title {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    line-height: 36px;
  }

  .crt_bxo {
    text-align: center;
    line-height: 24px;
  }

  .score_box {
    text-align: center;
    line-height: 24px;

    span {
      margin: 0 5px;
    }
  }

  .info_content {
    overflow: auto;
    height: calc(100% - 85px);
  }

  .info_list {
    h4 {
      line-height: 30px;
      font-size: 14px
    }

    p {
      line-height: 24px;
      margin-left: 20px
    }
  }
}
</style>
