<template>
  <div class="main_content">
    <div class="title_box">
      <span></span>
      <h5>试卷管理</h5>
    </div>
    <div class="line"></div>
    <div class="operate_box">
      <div class="search_box">
        <div class="input_box">
          <el-input placeholder="试卷名称" v-model="searchForm.title"></el-input>
        </div>
        <div class="input_box">
          <el-select v-model="searchForm.type" clearable placeholder="试卷类型">
            <el-option v-for="item in paperType" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="input_box"><el-button type="primary" class="el-icon-search" @click="searchBtn">搜索</el-button></div>
      </div>
      <div class="operate_btn">
        <el-button type="primary" icon="el-icon-circle-plus-outline" @click="addPaper">添加</el-button>
        <el-button type="danger" :plain="true" icon="el-icon-delete" @click="delMore">批量删除</el-button>
      </div>
    </div>
    <div class="tbale_box">
      <el-table :data="tableData" border style="width: 100%" @selection-change="handleSelectionChange" height="100%">
        <el-table-column type="selection" width="55" />
        <el-table-column fixed prop="serialNumber" label="序号" type="index" width="50" />
        <el-table-column prop="title" label="试卷名称" />
        <el-table-column prop="type" width="100" label="试卷类型">
          <template slot-scope="scope">
            <span v-if="scope.row.type == 1">考题形式</span>
            <span v-else>问卷形式</span>
          </template>
        </el-table-column>
        <el-table-column prop="questionSize" label="试题数量" width="80" />
        <el-table-column prop="questionScoreSum" label="试卷总分" width="80" />
        <el-table-column prop="testType" label="试卷状态" width="80">
          <template slot-scope="scope">
            <span v-if="scope.row.released" style="color: #02866a">已发布</span>
            <span v-else>未发布</span>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间" width="300" />
        <el-table-column prop="creatorName" label="创建人" width="100" />
        <el-table-column width="200" label="操作">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="编辑" placement="top" v-if="!scope.row.released">
              <el-button @click="editPaper(scope.row)" type="primary" icon="el-icon-edit" circle></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="详情" placement="top">
              <el-button type="primary" circle icon='el-icon-tickets' @click="paperInfo(scope.row)"></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="发布" placement="top" v-if="!scope.row.released">
              <el-button type="primary" icon="el-icon-s-promotion" circle @click="publishPaper(scope.row)"></el-button>
            </el-tooltip>
            <!--<el-tooltip class="item" effect="dark" content="推送" placement="top" v-if="!scope.row.released">-->
            <!--<el-button type="primary" circle icon='el-icon-message'></el-button>-->
            <!--</el-tooltip>-->
            <el-tooltip class="item" effect="dark" content="删除" placement="top" v-if="!scope.row.released">
              <el-button type="danger" circle icon='el-icon-delete' @click="delPaper(scope.row)"></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageBox.pageNum"
      :page-sizes="[10, 20, 50, 100, 200, 300, 400]" :page-size="pageBox.pageSize"
      layout="total, sizes, prev, pager, next, jumper" :total="pageBox.total" />
    <!-- <new-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-size=pageBox.pageSize
      layout="total,sizes, prev, pager, next" :total=pageBox.total>
    </new-pagination> -->
    <!--新增-->
    <el-dialog :visible.sync="addTestDialog" width="70%" :close-on-click-modal="false" :close-on-press-escape="false"
      class="set_dialog_style">
      <div slot="title" class="dialog_title">
        <span></span>{{ operateBox.title }}
      </div>
      <edit-from :operateBox="operateBox" :key="timer" @closed="closed"></edit-from>
    </el-dialog>
    <!--详情-->
    <el-dialog :visible.sync="paperInfoDialog" width="50%" :close-on-click-modal="false" :close-on-press-escape="false"
      class="set_dialog_style">
      <div slot="title" class="dialog_title">
        <span></span>试卷详情

      </div>
      <paper-details :key="timer" :paperId="paperId"></paper-details>
    </el-dialog>
  </div>
</template>

<script>
import EditFrom from './components/edit-from'
import api from '@/api/index'
import { paperType } from '@/utils/baseData'
import PaperDetails from './components/paper-details'
export default {
  name: 'index',
  components: { EditFrom, PaperDetails },
  data () {
    return {
      paperType: paperType,
      tableData: [],
      pageSize: 10,
      total: 50,
      operateBox: {
        title: '添加任务',
        operate: 'add',
        id: null
      },
      timer: null,
      searchForm: {
        title: '',
        type: null,
        userId: null
      },
      pageBox: {
        pageSize: 10,
        pageNum: 1,
        total: 0
      },
      addTestDialog: false,
      paperInfoDialog: false,
      paperId: null,
      delPaperIds: null
    }
  },
  created () {
    this.fetchUserPower()
  },
  methods: {

    // 根据UserID回显权限res,拿到医生权限管理的社区
    async fetchUserPower () {
      try {
        const res = await api.getUserPower(this.$store.state.userId)
        if (res.code == 200) {
          // if (this.$store.state.role !== 'admin') {
          //   // this.searchForm.communityIds = res.data.comId
          this.searchForm.userId = this.$store.state.userId
          // }
          this.examPapeListGet()
        }
      } catch (e) {

      }
    },

    // 获取试卷列表
    async examPapeListGet () {
      try {
        console.log('1:')
        const res = await api.getExamList(this.searchForm, this.pageBox)
        console.log('2:', res !== '')
        if (res.code == 200) {
          this.tableData = res.rows
          this.pageBox.total = res.total
        }
      } catch (e) {

      }
    },
    /* 一页显示多少条切换 */
    handleSizeChange (val) {
      this.pageBox.pageSize = val
      this.examPapeListGet()
    },
    /* 分页切换 */
    handleCurrentChange (val) {
      this.pageBox.pageNum = val
      this.examPapeListGet()
    },
    // 查询按钮
    searchBtn () {
      this.examPapeListGet()
    },

    // 发布试卷
    async publishPaper (row) {
      const res = await api.paperPublish([row.id])
      if (res.code == 200) {
        this.$message.success('发布成功！')
        this.examPapeListGet()
      }
    },
    // 新增样例
    addPaper () {
      this.operateBox = {
        title: '添加试卷',
        operate: 'add',
        id: null
      }
      this.timer = new Date().getTime()
      this.addTestDialog = true
    },
    editPaper (row) {
      this.operateBox = {
        title: '编辑试卷',
        operate: 'edit',
        id: row.id
      }
      this.timer = new Date().getTime()
      this.addTestDialog = true
    },

    // 选中要删除的商品管理获取对应id，选中要上架的商品获取对应id
    handleSelectionChange (val) {
      const ids = []
      val.forEach(item => {
        ids.push(item.id)
      })
      this.delPaperIds = ids
    },
    // 单个删除商品管理
    delPaper (row) {
      console.log(row)
      this.$confirm('确定删除改数据吗?', '信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.delPaperList([row.id])
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // 批量删除按钮
    delMore () {
      if (this.delPaperIds == null) {
        this.$message.info('请至少选中一条数据进行删除操作')
        return false
      }
      this.$confirm('确定删除该数据吗?', '信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.delPaperList(this.delPaperIds)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // 批量删除
    async delPaperList (ids) {
      try {
        const res = await api.paperListDel(ids)
        if (res.code == 200) {
          this.$message({
            type: 'success',
            message: '删除成功'
          })
          this.examPapeListGet()
        }
      } catch (e) {
        this.$message.error(e.msg)
      }
    },
    // 查看试卷详情
    paperInfo (row) {
      this.paperInfoDialog = true
      this.paperId = row.id
      this.timer = new Date().getTime()
    },

    closed () {
      this.addTestDialog = false
      this.examPapeListGet()
    }

  }
}
</script>

<style scoped>
.el-pagination {
  text-align: center;
}
</style>
